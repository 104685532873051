import { template as template_f03aab022db240f8a4c672cd0c4131c7 } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import textWithMultipleLang from '../helpers/text-with-multiple-lang.js';
export default template_f03aab022db240f8a4c672cd0c4131c7(`
  {{#each @banners as |banner|}}
    <PixBannerAlert @type={{banner.severity}} @canCloseBanner={{false}}>
      {{textWithMultipleLang banner.message}}
    </PixBannerAlert>
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
