import { template as template_b82625494b424e2aa2949475f0131b73 } from "@ember/template-compiler";
import PixBackgroundHeader from '@1024pix/pix-ui/components/pix-background-header';
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import CertificationStarter from '../certification-starter';
import CompanionBlocker from '../companion/blocker';
import AppLayout from '../global/app-layout';
export default template_b82625494b424e2aa2949475f0131b73(`
  <AppLayout>
    <CompanionBlocker>
      <main class="main" role="main">
        <PixBackgroundHeader id="main">
          <PixBlock @shadow="heavy" class="certification-start-page__block">
            <CertificationStarter @certificationCandidateSubscription={{@certificationCandidateSubscription}} />
          </PixBlock>
        </PixBackgroundHeader>
      </main>
    </CompanionBlocker>
  </AppLayout>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
