import { template as template_4b6d9cdaae49467a8d1d8600c7d31361 } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_4b6d9cdaae49467a8d1d8600c7d31361(`
  <ol class="module-objectives">
    {{#each @objectives as |objective|}}
      <li class="module-objectives__item">{{htmlUnsafe objective}}</li>
    {{/each}}
  </ol>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
