import { template as template_19bd2eb1a01c4e15ad3d344ca30361c7 } from "@ember/template-compiler";
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
export default class OidcProviderSelector extends Component {
    @tracked
    selectedProvider;
    get providerOptions() {
        const { providers = [] } = this.args;
        const options = providers.reduce((acc, provider)=>{
            return provider.isVisible ? [
                ...acc,
                {
                    label: provider.organizationName,
                    value: provider.id
                }
            ] : acc;
        }, []);
        return options.sort((option1, option2)=>option1.label.localeCompare(option2.label));
    }
    @action
    onProviderChange(value) {
        this.selectedProvider = value;
        if (this.args.onProviderChange) {
            this.args.onProviderChange(this.selectedProvider);
        }
    }
    static{
        template_19bd2eb1a01c4e15ad3d344ca30361c7(`
    <PixSelect
      @hideDefaultOption="true"
      @id="oidc-provider-selector"
      @isSearchable="true"
      @onChange={{this.onProviderChange}}
      @options={{this.providerOptions}}
      @placeholder={{t "components.authentication.oidc-provider-selector.placeholder"}}
      @searchLabel={{t "components.authentication.oidc-provider-selector.searchLabel"}}
      @searchPlaceholder={{t "components.authentication.oidc-provider-selector.searchLabel"}}
      @value={{this.selectedProvider}}
      class="oidc-provider-selector"
      ...attributes
    >
      <:label>{{t "components.authentication.oidc-provider-selector.label"}}</:label>
    </PixSelect>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
