import { template as template_19e85447ed124f28ba9b1667665573cc } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_19e85447ed124f28ba9b1667665573cc(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
