import { template as template_313caea0c0bc4b93a2a5e1ce9614ad3e } from "@ember/template-compiler";
export default template_313caea0c0bc4b93a2a5e1ce9614ad3e(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
